import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getFamilyDeployments = (options: object) => {
  return api.get(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family-deployments`,
    options
  );
};

const getProcesses = (options: object) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/process`, options);
};

const createFamilyDeployment = (data: object, options: object) => {
  return api.post(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family-deployment`,
    data,
    options
  );
};

const updateFamilyDeployment = (params: any, data: object, options: object) => {
  return api.put(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family-deployment/${params.family_id}/${params.process_id}/${params.ensemble_id}`,
    data,
    options
  );
};

const FamilyDeploymentService = {
  getFamilyDeployments,
  getProcesses,
  createFamilyDeployment,
  updateFamilyDeployment,
};

export default FamilyDeploymentService;
